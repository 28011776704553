import React from 'react';
import { Icon } from 'antd';

export const API_URL = 'https://api.tokfluence.com';

export const INFLUENCER_SIZE = {
  MINI: { id: '1K-5K', gte: '1', lt: '5' },
  LITTLE: { id: '5K-20K', gte: '5', lt: '20' },
  NANO: { id: '20K-100K', gte: '20', lt: '100' },
  MICRO: { id: '100K-1M', gte: '100', lt: '1000' },
  MACRO: { id: '1M+', gte: '1000' },
};
export const INFLUENCER_COUNTRY = {
  US: 'US',
  FR: 'FR',
  BE: 'BE',
  LU: 'LU',
  CH: 'CH',
  DE: 'DE',
  GB: 'GB',
  ES: 'ES',
  PT: 'PT',
  IN: 'IN',
  JP: 'JP',
  IT: 'IT',
  NL: 'NL',
  BR: 'BR',
  DK: 'DK',
  SE: 'SE',
  AT: 'AT',
  HR: 'HR',
  PL: 'PL',
  HU: 'HU',
};
export const SORT_TYPES = {
  tiktokFollowerCount: {
    key: 'tiktokFollowerCount',
    label: 'Followers',
  },
  tiktokAverageViews: {
    key: 'tiktokAverageViews',
    label: 'Average Views per video',
  },
  // instagramFollowerCount: {
  //   key: 'instagramFollowerCount',
  //   label: 'IG - Followers count',
  // },
  // instagramEngagementRate: {
  //   key: 'instagramEngagementRate',
  //   label: 'IG - Eng. Rate',
  // },
  // youtubeSubscriberCount: {
  //   key: 'youtubeSubscriberCount',
  //   label: 'YT - Followers count',
  // },
};

export const SORT_TYPES_STARTER = ['tiktokFollowerCount'];

export const SORT_ORDER = {
  asc: {
    key: 'asc',
    label: <Icon type="up" />,
  },
  desc: {
    key: 'desc',
    label: <Icon type="down" />,
  },
};

export const STAGES = {
  TO_CONTACT: 'TO CONTACT',
  CONTACTING: 'CONTACTING',
  INTERVIEWING: 'INTERVIEWING',
  COLLABORATING: 'COLLABORATING',
};

export const PROFILE_PIC_TYPES = [
  'WOMAN_FACE',
  'MAN_FACE',
  'UNKNOWN_FACE',
  'NO_FACE',
  'DEFAULT',
  'INACCESSIBLE',
];

export const PROFILE_PIC_TYPES_RADIO = {
  ONLY_MEN: ['MAN_FACE'],
  ONLY_WOMEN: ['WOMAN_FACE'],
  ONLY_HUMANS: ['WOMAN_FACE', 'MAN_FACE'],
  NO_FACE: ['NO_FACE'],
  NO_PIC: ['DEFAULT', 'INACCESSIBLE'],
};

export const getProfilePicTypeTextFromId = (id) => {
  switch (id) {
    case 'ONLY_MEN':
      return 'Only men';
    case 'ONLY_WOMEN':
      return 'Only women';
    case 'ONLY_HUMANS':
      return 'Only humans';
    case 'NO_FACE':
      return 'No face';
    default:
    case 'NO_PIC':
      return 'No picture';
  }
};

export const PLANS = {
  FREE: 'FREE',
  STARTER: 'STARTER',
  STARTUP: 'STARTUP',
  BUSINESS: 'BUSINESS',
  BUSINESS_V2: 'BUSINESS_V2',
  BUSINESS_V3: 'BUSINESS_V3',
  PLATINUM: 'PLATINUM',
  ENTERPRISE_MARIA: 'ENTERPRISE_MARIA',
  ENTERPRISE_BANDALABS: 'ENTERPRISE_BANDALABS',
  ENTERPRISE_BRENDAN: 'ENTERPRISE_BRENDAN',
  ENTERPRISE_MIMAMU: 'ENTERPRISE_MIMAMU',
  ENTERPRISE_BUTR_2: 'ENTERPRISE_BUTR_2',
  ENTERPRISE_LOOKWIDE: 'ENTERPRISE_LOOKWIDE',
  ENTERPRISE_LENARD: 'ENTERPRISE_LENARD',
  ENTERPRISE_RADHA: 'ENTERPRISE_RADHA',
  ENTERPRISE_SOCIALFUTURE: 'ENTERPRISE_SOCIALFUTURE',
  ENTERPRISE_VYTEN: 'ENTERPRISE_VYTEN',
  GOLD: 'GOLD',
};
// Note: existing plans = STARTUP, BUSINESS_V3

export const RESULTS_LIMIT_FREE = 5;

export const PLAN_IDS = {
  STARTER_MONTHLY: 'starter-monthly',
  STARTER_ANNUALLY: 'starter-annually',
  STARTUP_MONTHLY: 'startup-monthly',
  STARTUP_ANNUALLY: 'startup-annually',
  BUSINESS_MONTHLY: 'business-v3-monthly',
  BUSINESS_ANNUALLY: 'business-v3-annually',
  GOLD_MONTHLY: 'gold-monthly',
  GOLD_ANNUALLY: 'gold-annually',
  ENTERPRISE_MONTHLY: 'enterprise-monthly',
  ENTERPRISE_ANNUALLY: 'enterprise-annually',
  PLATINUM_MONTHLY: 'platinum-monthly-fix',
  PLATINUM_ANNUALLY: 'platinum-annually',
  DATABASE_M: 'database-monthly',
  DATABASE_A: 'database-yearly',
};
export const getPlanPublicName = (id) => {
  switch (id) {
    case PLANS.BUSINESS_V2:
    case PLANS.BUSINESS_V3:
      return 'BUSINESS';
    case PLANS.ENTERPRISE_MARIA:
    case PLANS.ENTERPRISE_BRENDAN:
    case PLANS.ENTERPRISE_LOOKWIDE:
    case PLANS.ENTERPRISE_MIMAMU:
    case PLANS.ENTERPRISE_BANDALABS:
    case PLANS.ENTERPRISE_LENARD:
    case PLANS.ENTERPRISE_RADHA:
    case PLANS.ENTERPRISE_SOCIALFUTURE:
    case PLANS.ENTERPRISE_BUTR_2:
    case PLANS.ENTERPRISE_VYTEN:
      return 'ENTERPRISE';
    default:
      return id;
  }
};
export const getSubscriptionNameFromId = (id) => {
  switch (id) {
    case PLAN_IDS.STARTER_MONTHLY:
    case PLAN_IDS.STARTER_ANNUALLY:
      return 'Starter 💥';
    case PLAN_IDS.STARTUP_MONTHLY:
    case PLAN_IDS.STARTUP_ANNUALLY:
      return 'Startup 💥';
    case PLAN_IDS.BUSINESS_MONTHLY:
    case PLAN_IDS.BUSINESS_ANNUALLY:
      return 'Business 🔥';
    case PLAN_IDS.PLATINUM_MONTHLY:
    case PLAN_IDS.PLATINUM_ANNUALLY:
      return 'Platinum 🚀';
    case PLAN_IDS.GOLD_ANNUALLY:
    case PLAN_IDS.GOLD_MONTHLY:
      return 'Gold ⭐️';
    case PLAN_IDS.DATABASE_M:
    case PLAN_IDS.DATABASE_A:
      return 'Database export 🗃️';
    case PLAN_IDS.ENTERPRISE_MONTHLY:
    case PLAN_IDS.ENTERPRISE_ANNUALLY:
      return 'Enterprise 🏢';
    case 'credits-one':
      return 'One Credit';
    case 'credits-ten':
      return 'Ten Credit';
    case 'credits-fifty':
      return 'Fifty Credit';
    default:
      return id;
  }
};

export const getStripeToken = () => 'pk_live_MUOKpmyGPPuFwiowWN6MUnfY00BsBjnI1w';

export const getSubscriptionDescriptionFromId = (id) => {
  switch (id) {
    case PLAN_IDS.STARTER_MONTHLY:
    case PLAN_IDS.STARTER_ANNUALLY:
      return (
        <div>
          <b>Basic search</b>
          {' '}
(Followers, Engagement, Country)
          <br />
          <b>3 active campaigns</b>
          <br />
          <b>500 contact export/mo</b>
          {' '}
(campaign data, including emails)
        </div>
      );
    case PLAN_IDS.STARTUP_MONTHLY:
    case PLAN_IDS.STARTUP_ANNUALLY:
      return (
        <div>
          <b>All search criteria</b>
          <br />
          <b>100 searches/mo</b>
          <br />
          <b>Unlimited campaigns</b>
          <br />
          <b>50 contact export/mo</b>
          {' '}
(including emails and phone numbers)
          <br />
          {/* <b>10 Audience report credits/mo</b>
          <br /> */}
          <b>1 seat</b>
        </div>
      );
    case PLAN_IDS.BUSINESS_MONTHLY:
    case PLAN_IDS.BUSINESS_ANNUALLY:
      return (
        <div>
          <b>All search criteria</b>
          <br />
          <b>Unlimited searches</b>
          <br />
          <b>Unlimited campaigns</b>
          <br />
          <b>500 contact export/mo</b>
          {' '}
(including emails and phone numbers)
          <br />
          {/* <b>50 Audience report credits/mo</b>
          <br /> */}
          <b>1 seat</b>
        </div>
      );
    case PLAN_IDS.PLATINUM_MONTHLY:
    case PLAN_IDS.PLATINUM_ANNUALLY:
      return (
        <div>
          <b>All search criteria</b>
          <br />
          <b>Unlimited searches</b>
          <br />
          <b>Unlimited campaigns</b>
          <br />
          <b>2,000 contact export/mo</b>
          {' '}
(including emails and phone numbers)
          <br />
          {/* <b>100 Audience report credits/mo</b>
          <br /> */}
          <b>1 seat</b>
        </div>
      );
    case PLAN_IDS.DATABASE_M:
    case PLAN_IDS.DATABASE_A:
      return (
        <div>
          <b>Full database export (10M+ profiles)a</b>
          <br />
          <b>
            All data points included (IDs, emails, profile links, follower
            count, following count, videos, number of views (total, average...),
            likes, mentions, tags etc.)
          </b>
          <br />
          <b>Export file sent directly to your inbox</b>
          <br />
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tokfluence.com/static/tokfluence_users_sample.xlsx"
            >
              Download a sample
            </a>
          </b>
        </div>
      );
    case PLAN_IDS.GOLD_ANNUALLY:
    case PLAN_IDS.GOLD_MONTHLY:
      return (
        <div>
          <b>Business plan features</b>
          <br />
          <b>Unlimited searches</b>
          <br />
          <b>Campaign Tracking (Posts performances and link tracking)</b>
          <br />
          <b>12 active campaigns</b>
          {' '}
with statistics and analysis
          <br />
          <b>1200 contact export/mo</b>
          {' '}
(including emails and phone numbers)
          <br />
          <b>40 Audience report credits/mo</b>
        </div>
      );
    case PLAN_IDS.ENTERPRISE_MONTHLY:
    case PLAN_IDS.ENTERPRISE_ANNUALLY:
      return 'Business plan features + custom needs, on-demand integrations, CRM integrations, Up to 10k exports, API access, Dedicated customer success manager.';
    default:
      return 'Search tool and campaign management.';
  }
};

export const FEATURE_FLAG = {
  followed_by: [
    '5e7a6858480aa2502d1be171', // fab
    '5e37325e741f422076775a0e', // daniel
    '5e25abff40b3c375d6af2a7b', // jy
  ],
  bulk_add: [
    '5e7a6858480aa2502d1be171', // fab
    '5e37325e741f422076775a0e', // daniel
    '5e25abff40b3c375d6af2a7b', // jy
    '5f86fbc38ac46f46eaff839e', // younow
    '5fa83272984e92592c44d8ae', // freeskins
  ],
};

export const LINE_DATA = {
  legend: {
    display: false,
  },
  fill: true,
  lineTension: 0.5,
  lineJoin: 'round',
  backgroundColor: 'rgb(251, 2, 84,0.3)',
  borderColor: '#fb0154',
  borderCapStyle: 'butt',
  borderDash: [],
  borderJoinStyle: 'miter',
  pointBorderColor: '#fb0154',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: '#fb0154',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

export const FEATURES = {
  CREATE_CAMPAIGN: 'create_campaign',
  TRACKING: 'tracking',
  EXPORT: 'export',
  FOLLOWED_BY: 'followed_by',
  CAMPAIGN_DETAILS: 'campaign_details',
  SEE_PHONE_NUMBER: 'see_phone_number',
  ADVANCED_SEARCH: 'advanced_search',
  SEE_EMAILS: 'see_emails',
  FULL_DATABASE: 'full_database',
  HELP_SEARCH: 'help_search',
  BULK_ADD: 'bulk_add',
};

export const CURRENT_BANNER_ID = 'hideBannerTracking';

export const MAX_CREATORS_PER_CAMPAIGN = 500;

export const GOALS = [
  {
    id: 'FIND_INFLUENCERS',
    title: 'Find influencers',
    description: 'Find influencers',
    picture: 'img/onboarding/1find.png',
  },
  {
    id: 'GET_CONTACT_INFO',
    title: 'Get influencers contact information',
    description: 'Get influencers contact information',
    picture: 'img/onboarding/2emails.png',
  },
  {
    id: 'AUDIENCE_ANALYSIS',
    title: 'Audit influencers with audience reports',
    description: 'Audit influencers with audience reports',
    picture: 'img/onboarding/3audience.png',
  },
  {
    id: 'RUN_CAMPAIGNS',
    title: 'Manage TikTok influence marketing campaigns',
    description: 'Manage TikTok influence marketing campaigns',
    picture: 'img/onboarding/4campaigns.png',
  },
  {
    id: 'TRACK_RESULTS',
    title: 'Track TikTok influence marketing campaigns',
    description: 'Track TikTok influence marketing campaigns',
    picture: 'img/onboarding/5track.png',
  },
];

export default API_URL;
