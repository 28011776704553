/* eslint-disable no-trailing-spaces */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import '../components/i18n';
import flag from 'country-code-emoji';
import {
  Icon,
  Tooltip,
  Avatar,
  Tag,
  Empty,
  Table,
  Pagination,
  Dropdown,
  Menu,
  message,
  Modal,
  Card,
  Skeleton,
  Popover,
  Button,
} from 'antd';
import Moment from 'moment';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TKIcon from '../components/tkIcon';
import EditNote from '../components/editNote';
import Header from '../components/header';
import TiktokAccount from '../components/tiktokAccount';
import SearchForm from '../components/searchForm';
import FeedbackModal from '../components/feedbackModal';
import NumberCountBadge from '../components/numberCountBadge';
import UpgradePanel from '../components/upgradePanel';
import {
  parseSearch,
  getCountLabel,
  stringifySearch,
  showUpgradeModal,
  getKeywordsStringFromArray,
  getTagsStringFromArray,
  isValidTikTokUsername,
  getTotalCreatorsFromStageObject,
  isAuthorized,
} from '../services/utils';
import { isConnected } from '../services/user';
import { search, countInfluencers } from '../services/search';
import {
  listCampaigns, addInfluencerToCampaign,
  RemoveInfluencerFromCampaign,
  addBulkInfluencersToCampaign,
} from '../services/campaigns';
import {
  PROFILE_PIC_TYPES_RADIO, PLANS, RESULTS_LIMIT_FREE, MAX_CREATORS_PER_CAMPAIGN,
  FEATURES,
} from '../services/constants';
import { getCountryNameFromCode } from '../services/country';
import '../styles/global.less';
import '../styles/search.less';

const { Meta } = Card;

class Search extends Component {
  state={
    loading: false,
    countLoading: false,
    results: null,
    count: null,
    campaigns: [],
    tableMode: true,
    width: null,
    editNoteInfluencer: null,
    notePopoverVisible: false,
    searchLimitReached: false,
    selectedRowKeys: [],
  }

  constructor(props) {
    super(props);
    if (!isConnected()) {
      this.props.history.push('/login?next=search');
    } else {
      ReactGA.pageview(`/${window.location.hash}`);
    }
  }

  componentDidMount() {
    this.verifyPlanRestrictions();
    this.searchInfluencers();
    this.getCampaigns();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const searches = parseSearch(this.props.location.search);
      const prevSearches = parseSearch(prevProps.location.search);
      if (searches.accountDetails === prevSearches.accountDetails) {
        this.searchInfluencers();
        this.verifyPlanRestrictions();
      }
    }
    if (prevProps.profile !== this.props.profile) {
      this.verifyPlanRestrictions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  verifyPlanRestrictions = () => {
    const {
      page,
    } = parseSearch(this.props.location.search);
    if (!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && parseInt(page || '1', 10) > 1) {
      this.onPageChange(1);
    }
  }

  updateWindowDimensions=() => {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth }, () => this.toggleDisplayModeIfScreenSmall());
    }
  }

  toggleDisplayModeIfScreenSmall = () => this.setState(prevState => ({
    tableMode: prevState.width > 800,
  }));

  callbackSuccess =(note, id) => this.setState(prevState => ({
    results: prevState.results.reduce((acc, current) => [
      ...acc,
      (current._id === id)
        ? { ...current, notes: note } : current,
    ], []),
  }));

  searchInfluencers = () => {
    const {
      type,
      countries,
      page,
      limit,
      verified,
      email,
      insta,
      yt,
      instamin,
      instamax,
      ermin,
      instaminpost,
      tiktokminvideos,
      ppw,
      instappw,
      sorttype,
      sortorder,
      gender,
      keywords,
      verifiedInsta,
      instaermin,
      verifiedYoutube,
      ytmin,
      ytmax,
      hashtags,
      mentions,
      tiktokAverageViews,
    } = parseSearch(this.props.location.search);
    const countriesList = countries ? countries.split(',') : [];
    const types = type ? type.split(',') : [null, null];
    this.setState({ loading: true, countLoading: true });

    const tiktokFollowerCount = {
      gte: parseInt(types[0], 0) || undefined,
      lt: parseInt(types[1], 0) === 1000000 ? undefined : (parseInt(types[1], 0) || undefined),
    };

    const options = ({
      type: (tiktokFollowerCount.gte || tiktokFollowerCount.lt) ? tiktokFollowerCount : undefined,
      country: countriesList,
      page: (!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) ? 0 : (parseInt(page || '1', 10) - 1),
      limit: (!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) ? 20 : parseInt(limit || '20', 10),
      isTiktokVerified: (verified && verified === 'true') ? true : undefined,
      instagramVerified: (verifiedInsta && verifiedInsta === 'true') ? true : undefined,
      isYoutubeVerified: (verifiedYoutube && verifiedYoutube === 'true') ? true : undefined,
      hasContactEmail: (email && email === 'true') ? true : undefined,
      hasInstagramLinked: (insta && insta === 'true') ? true : undefined,
      hasYoutubeLinked: (yt && yt === 'true') ? true : undefined,
      instagramFollowerCount: ((instamin && parseInt(instamin, 0) > 0) || instamax)
        ? { gte: parseInt(instamin, 0) || undefined, lt: parseInt(instamax, 0) || undefined }
        : undefined,
      youtubeSubscriberCount: ((ytmin && parseInt(ytmin, 0) > 0) || ytmax)
        ? { gte: parseInt(ytmin, 0) || undefined, lt: parseInt(ytmax, 0) || undefined }
        : undefined,
      tiktokEngagementRate: ermin ? { gte: parseInt(ermin, 0) } : undefined,
      instagramEngagementRate: instaermin ? { gte: parseInt(instaermin, 0) } : undefined,
      instagramMediaCount: instaminpost ? { gte: parseInt(instaminpost, 0) } : undefined,
      tiktokVideoCount: tiktokminvideos ? { gte: parseInt(tiktokminvideos, 0) } : undefined,
      postsPerWeek: ppw ? {
        gte: parseInt(ppw, 0),
      } : undefined,
      instagramPostsPerWeek: instappw ? {
        gte: parseInt(instappw, 0),
      } : undefined,
      tiktokAverageViews: tiktokAverageViews ? {
        gte: parseInt(tiktokAverageViews, 0),
      } : undefined,
      sort: sorttype || undefined,
      sortOrder: sortorder || undefined,
      profilePicTypes: (gender && gender !== 'NONE') ? PROFILE_PIC_TYPES_RADIO[gender] : undefined,
      profileBiographiesQuery: (keywords && keywords.length > 0) ? getKeywordsStringFromArray(keywords.split(',')) : undefined,
      tags: (hashtags && hashtags.length > 0) ? getTagsStringFromArray(hashtags.split(',')) : undefined,
      mentions: (mentions && mentions.length > 0) ? getTagsStringFromArray(mentions.split(',')) : undefined,
    });
    search(options).then((res) => {
      window.scrollTo(0, 0);
      this.setState({ results: res.data });
    }).catch((err) => {
      if (err && err.response && err.response.status === 429) {
        this.setState({ searchLimitReached: true });
      } else {
        console.error(err);
      }
    })
      .finally(() => { this.setState({ loading: false }); });
    countInfluencers(options).then((res) => {
      this.setState({ count: res.data });
    }).catch(error => console.error('count', error))
      .finally(() => this.setState({ countLoading: false }));
  }

  onPageChange = (page) => {
    if (!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) {
      showUpgradeModal({ onOk: () => this.props.history.push('/settings/?upgrade=true') });
    } else {
      const currentSearch = parseSearch(this.props.location.search);
      const newSearch = { ...currentSearch, page: `${page}` };
      if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
        this.props.history.push({
          pathname: '/search',
          search: stringifySearch(newSearch),
        });
      }
    }
  };

  onPageSizeChange = (a, pageSize) => {
    if (!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) {
      showUpgradeModal({ onOk: () => this.props.history.push('/settings/?upgrade=true') });
    } else {
      const currentSearch = parseSearch(this.props.location.search);
      const newSearch = { ...currentSearch, limit: `${pageSize}`, page: 1 };
      if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
        this.props.history.push({
          pathname: '/search',
          search: stringifySearch(newSearch),
        });
      }
    }
  };

  addThisInfluencerToCampaign = (influencer, campaign) => {
    if (getTotalCreatorsFromStageObject(campaign.stage) >= MAX_CREATORS_PER_CAMPAIGN) {
      message.error('Campaign is full');
    } else {
      this.setState({ loading: true });
      addInfluencerToCampaign(campaign._id, influencer._id).then(() => {
        message.success(<span>
  Influencer added
          {' - '}
          <a href={`/#/campaigns/${campaign._id}`}>View list</a>
        </span>, 4);
        this.afterAddInfluencerCallback(influencer, campaign);
      }).catch((e) => {
        console.error(e);
        if (e.response.status === 409) {
          message.error('Influencer already in this list');
        } else {
          message.error('Error while adding influencer');
        }
      }).finally(() => { this.setState({ loading: false }); });
    }
  }

  removeInfluencerFromCampaign = (influencer, campaign) => {
    this.setState({ loading: true });
    RemoveInfluencerFromCampaign(campaign._id, influencer._id).then(() => {
      message.success(<span>
Influencer removed
        {' - '}
        <a href={`/#/campaigns/${campaign._id}`}>View list</a>
      </span>, 4);
      this.afterRemoveInfluencerCallback(influencer, campaign);
    }).catch((e) => {
      console.error(e);
      if (e.response.status === 409) {
        message.error('Influencer not in the campaign');
      } else {
        message.error('Error while removing influencer');
      }
    }).finally(() => { this.setState({ loading: false }); });
  }

  afterRemoveInfluencerCallback = (influencer, campaign) => {
    const modifiedResults = this.state.results.map((res) => {
      if (res._id === influencer._id) {
        return ({
          ...res,
          campaigns: influencer.campaigns.filter(c => c._id !== campaign._id),
        });
      }
      return res;
    });
    this.setState(
      { results: modifiedResults },
    );
  }

  afterAddInfluencerCallback = (influencer, campaign) => {
    const modifiedResults = this.state.results.map((res) => {
      if (res._id === influencer._id) {
        return ({
          ...res,
          campaigns: [
            ...influencer.campaigns,
            campaign,
          ],

        });
      }
      return res;
    });
    this.setState(
      { results: modifiedResults },
    );
  }

  afterAddInfluencersCallback = (campaign) => {
    const modifiedResults = this.state.results.map(res => ({
      ...res,
      campaigns: [
        ...res.campaigns,
        campaign,
      ],

    }));
    this.setState(
      { results: modifiedResults },
    );
  }

  getCampaigns = () => listCampaigns()
    .then(res => this.setState({ campaigns: res.data }))
    .catch(error => console.error(error))

    closeAccountPreviewModale = () => {
      const currentSearch = parseSearch(this.props.location.search);
      delete currentSearch.accountDetails;
      this.props.history.push({
        pathname: '/search',
        search: stringifySearch(currentSearch),
      });
    };

    openAccountPreviewModale = (accountId) => {
      const currentSearch = parseSearch(this.props.location.search);
      const newSearch = { ...currentSearch, accountDetails: accountId };
      if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
        this.props.history.push({
          pathname: '/search',
          search: stringifySearch(newSearch),
        });
      }
    };

    showDataFromUser = account => this.openAccountPreviewModale(account);

    openEditNoteOnInfluencer = account => this.setState({
      editNoteInfluencer: account,
      notePopoverVisible: false,
    })

    closeEditNoteOnInfluencer = () => this.setState({ editNoteInfluencer: null })

    onVisibleChange = (visible, id, userid) => this.setState({ [id]: visible ? userid : null });

    getNotePopover = (record, isButton = true, disabled = false) => (
      <Popover
        disabled={disabled}
        overlayClassName="Note_popover_element"
        placement="leftTop"
        title="Note"
        onVisibleChange={e => this.onVisibleChange(e, 'notePopoverVisible', record._id)}
        visible={(this.state.notePopoverVisible && !disabled)
          ? this.state.notePopoverVisible === record._id : false}
        content={record.notes
          ? (
            <div>
              <div>{record.notes}</div>
              <div style={{ marginTop: '10px' }}>
                <Button style={{ width: '100%' }} onClick={() => this.openEditNoteOnInfluencer(record)} type="primary">
            Edit note
                </Button>
              </div>
            </div>
          ) : (
            <Empty description="No note yet.">
              <Button onClick={() => this.openEditNoteOnInfluencer(record)} type="primary">Create Now</Button>
            </Empty>
          )}
        trigger="click"
      >
        {isButton ? (
          <Tooltip
            trigger={['hover']}
            title="Show users's note"
          >
            <Button
              disabled={disabled}
              type={record.notes ? 'primary' : null}
              icon="message"
            />
          </Tooltip>

        ) : <Icon type="message" style={{ color: record.notes ? '#7070fd' : null }} />}
      </Popover>
    );

    onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
    };

    addBulkSelection= (campaign) => {
      const { selectedRowKeys } = this.state;
      this.setState({ loading: true }, () => {
        addBulkInfluencersToCampaign(campaign._id, selectedRowKeys).then(() => {
          message.success(<span>
      Influencers added
          </span>, 4);
          this.afterAddInfluencersCallback(campaign);
        }).catch((e) => {
          console.error(e);
          if (e.response.status === 409) {
            message.error('Influencers already in this list');
          } else {
            message.error('Error while adding influencers');
          }
        }).finally(() => { this.setState({ loading: false }); });
      });
    }

    render() {
      const {
        results,
        loading,
        countLoading,
        campaigns,
        tableMode,
        searchLimitReached,
        selectedRowKeys,
      } = this.state;
      const {
        page, limit, accountDetails, firstvisit,
      } = parseSearch(this.props.location.search);

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };

      const { profile } = this.props;

      const isFirstVisit = firstvisit === 'true';

      const columns = [
        {
          title: '',
          dataIndex: 'index',
          key: 'index',
          render: (text, account, i) => (
            <span>
              {(page)
                ? i + 1 + ((page - 1) * (limit || 20))
                : i + 1}
            </span>
          ),
        },
        {
          title: <div className="Tiktok_logo_header_container">
            <img className="Tiktok_logo_header_img" src="img/tiktok_logo.png" alt="tiktok" />
          </div>,
          dataIndex: 'profilePic',
          key: 'profilePic',
          render: (text, account) => (
            <a
              href={`https://www.tiktok.com/@${isValidTikTokUsername(account.username) ? account.username : account.secUid}`}
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {(account.profilePicUrl ? (
                <Avatar size={30} src={account.profilePicUrl} />
              ) : (
                <Avatar size={30} icon="user" />
              ))}
            </a>
          ),
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          render: (text, account) => (
            <>
              {`${text} `}
              {account.verified && <img src="img/verified.png" alt="verified" className="Verified_logo_search" />}
            </>
          )
          ,
        },
        {
          title: 'Country / Email',
          dataIndex: 'details',
          key: 'details',
          render: (text, account) => (
            <div className="Account_options">
              <Tooltip title={(getCountryNameFromCode(account.region) || {}).name}>
                <Tag> 
                  <span style={{ fontSize: 20, lineHeight: '22px' }}>
                    {flag(account.region)}
                  </span>
                </Tag>
              </Tooltip>
              {account.hasEmail && (
              <div>
                <Tooltip
                  trigger={['hover']}
                  title="Click on the line to display user's data"
                >
                  <Tag color="blue"><Icon type="mail" /></Tag>
                </Tooltip>
              </div>
              )}
            </div>
          ),
        },
        {
          title: 'Views/Vid.',
          dataIndex: 'averageViewsPerVideo',
          key: 'averageViewsPerVideo',
          render: text => (
            <>
              <Icon type="eye" />
              {` ${getCountLabel(text)}`}
            </>
          ),
        },
        // {
        //   title: 'Videos',
        //   dataIndex: 'videosCount',
        //   key: 'videosCount',
        //   render: text => (
        //     <>
        //       <Icon type="video-camera" />
        //       {` ${getCountLabel(text)}`}
        //     </>
        //   ),
        // },
        {
          title: 'Vid./week',
          dataIndex: 'postsPerWeek',
          key: 'postsPerWeek',
          render: text => (
            <>
              <Icon type="video-camera" />
              {` ${text ? `${getCountLabel(text)}/w` : '-'}`}
            </>
          ),
        },
        {
          title: 'Eng. Rate',
          dataIndex: 'globalEngagementRate',
          key: 'globalEngagementRate',
          render: text => (
            <>
              <Icon type="like" />
              {` ${getCountLabel(text)}`}
            </>
          ),
        },
        {
          title: 'Followers',
          dataIndex: 'followerCount',
          key: 'followerCount',
          render: (text) => (
            <div className="flex">
              <NumberCountBadge icon={<TKIcon />} color="black" number={getCountLabel(text)} />
              {/* {account.instagramProfile && account.instagramProfile.followerCount && <NumberCountBadge icon={<Icon type="instagram" />} color="#5ab0ff" number={getCountLabel(account.instagramProfile.followerCount)} />}
              {account.youtubeChannel && account.youtubeChannel.subscribers && <NumberCountBadge icon={<Icon type="youtube" />} color="#f4252f" number={getCountLabel(account.youtubeChannel.subscribers)} />} */}
            </div>
          ),
        },
        {
          title: 'Actions',
          key: 'action',
          render: (text, record, index) => {
            const isInOneCampaignAlready = (record.campaigns.find(
              c => c.archived === false,
            )) !== undefined;
            return (
              <span>
                <div style={{ minWidth: '70px' }}>
                  <Button.Group onClick={(e) => {
                    if (this.props.profile.plan !== PLANS.FREE || index < RESULTS_LIMIT_FREE) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  >
                    {this.getNotePopover(record,
                      true,
                      !!(!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && index >= RESULTS_LIMIT_FREE))}

                    <Dropdown
                      disabled={
                        !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && index > RESULTS_LIMIT_FREE - 1
                    }
                      trigger={['click']}
                      overlay={(
                        <Menu>
                          {(campaigns && campaigns.length > 0) ? campaigns.map((c) => {
                            const isInfluencerAlreadyInCampaign = record.campaigns.find(
                              camp => camp._id === c._id,
                            );
                            return (
                              <Menu.Item
                                key={c._id}
                                disabled={isInfluencerAlreadyInCampaign}
                                className={isInfluencerAlreadyInCampaign ? 'Search_campaign_select' : ''}
                              >
                                <a onClick={(e) => {
                                  e.stopPropagation();
                                  if (isInfluencerAlreadyInCampaign) {
                                    this.removeInfluencerFromCampaign(record, c);
                                  } else {
                                    this.addThisInfluencerToCampaign(record, c);
                                  }
                                }}
                                >
                                  {isInfluencerAlreadyInCampaign && (
                                  <Icon
                                    type="check"
                                    className="Green_check"
                                    style={{ marginRight: '4px' }}
                                  />
                                  )}
                                  {c.name}
                                </a>
                              </Menu.Item>
                            );
                          }) : <span>No campaign found</span>}
                        </Menu>
)}
                    >
                      <Tooltip
                        trigger={['hover']}
                        title="Add the user to a list"
                      >
                        <Button
                          onClick={(e) => {
                            if (this.props.profile.plan !== PLANS.FREE
                            || index < RESULTS_LIMIT_FREE) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          disabled={
                            !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && index >= RESULTS_LIMIT_FREE
                      }
                          icon="plus"
                          type={isInOneCampaignAlready ? 'primary' : ''}
                        />
                      </Tooltip>

                    </Dropdown>
                  </Button.Group>
                </div>

              </span>
            );
          },
        },
      ];

      return (
        <div className="App">
          <Header profile={this.props.profile} />
          <SearchForm profile={this.props.profile} />
          <div className="center div-wrapper Search_main_wrapper">
            <div className="Search_account_result_wrapper">
              {(this.props.profile && !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) && (
              <>
                {profile.stripeSubscription
  && profile.stripeSubscription.invoice
  && profile.stripeSubscription.invoice.hosted_invoice_url
                  ? (
                    <div>
                      <UpgradePanel
                        displayPricing={false}
                        title="Payment failed"
                        description="A payment has failed, please click the button below to try again."
                        actions={(
                          <a
                            href={profile.stripeSubscription.invoice.hosted_invoice_url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <Button type="primary">Pay invoice</Button>
                          </a>
)}
                      />
                    </div>
                  ) : (
                    <UpgradePanel />
                  )}
              </>
              )}
              {
              profile && searchLimitReached
                ? (
                  <div>
                    <Empty description={false} />
                    <h1>Search quota reached</h1>
                    <div>
You've reached your search limit. 2 options to keep searching:
                      <ul style={{ textAlign: 'left' }}>
                        <li>
                          <b>Upgrade</b>
                          {' '}
to have more search credits:
                          {' '}
                          <a href="/#/settings?upgrade=true">Click here to upgrade</a>
                        </li>
                        {profile.plan === PLANS.FREE ? (
                          <li>
                            <b>Wait</b>
                            {' '}
to have your quota regenerated
                          </li>
                        ) : (
                          <li>
                            <b>Wait</b>
                            {' '}
until your renewal date
                            {' '}
                            {profile.stripeSubscription && profile.stripeSubscription.current_period_end && (
                            <>
(
                              {`${new Moment(
                                profile.stripeSubscription.current_period_end * 1000,
                              ).format('MM/DD/YYYY')}`}
)
                              {' '}
                            </>
                            )}
to have your quota regenerated
                          </li>
                        )}

                      </ul>
                    </div>
                  </div>
                )
                : (!loading && (!results || results.length === 0))
                  ? <Empty />
                  : (
                    <>
                      <div className="Search_actions_wrapper">
                        {profile && isAuthorized(profile, FEATURES.BULK_ADD) && (
                          <div className="Group_actions">
                            <Dropdown
                              disabled={(selectedRowKeys.length < 1)}
                              trigger={['click']}
                              overlay={(
                                <Menu>
                                  {(campaigns && campaigns.length > 0) ? campaigns.map(c => (
                                    <Menu.Item
                                      key={c._id}
                                    >
                                      <a onClick={(e) => {
                                        e.stopPropagation();
                                        this.addBulkSelection(c);
                                      }}
                                      >
                                        {c.name}
                                      </a>
                                    </Menu.Item>
                                  )) : <span>No campaign found</span>}
                                </Menu>
)}
                            >
                              <Button>
                        Add all to list
                                {' '}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </div>
                        )}
                        <div className="Results_count_top">
                          {profile && isAuthorized(this.props.profile, FEATURES.HELP_SEARCH) && (
                          <div>
                            <Button
                              onClick={() => {
                                ReactGA.event({
                                  category: 'Help_search',
                                  action: 'openModal',
                                });
                                Modal.confirm({
                                  icon: 'info-circle',
                                  title: 'Need help with the search ?',
                                  content: 'Let\'s review together how to fully take advantage of Tokfluence\'s search during a call.',
                                  okText: 'Book a call',
                                  onOk: () => {
                                    ReactGA.event({
                                      category: 'Help_search',
                                      action: 'openCalendly',
                                    });
                                    window.open('https://calendly.com/tokfluence/help-with-search');
                                  },
                                  onCancel: () => {
                                    ReactGA.event({
                                      category: 'Help_search',
                                      action: 'closeModal',
                                    });
                                  },
                                });
                              }}
                              type="link"
                            >
                              {'Need help with the search ? '}
                            </Button>
                          </div>
                          )}
                          {this.state.count && (
                          <div>
                            {`${this.state.count > 999999 ? getCountLabel(this.state.count) : this.state.count} results`}
                          </div>
                          )}
                        </div>
                      </div>

                      {(tableMode) ? (
                        <Table
                          className="Table_results"
                          loading={loading}
                          columns={columns}
                          dataSource={results}
                          pagination={false}
                          size="small"
                          rowKey="_id"
                          rowSelection={isAuthorized(profile, FEATURES.BULK_ADD) ? rowSelection : null}
                          rowClassName={(record, index) => `Table_results_row ${(index >= RESULTS_LIMIT_FREE && !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) ? 'blur' : ''}`}
                          onRow={(record, index) => ({
                            onClick: (e) => {
                              e.preventDefault();
                              if (
                                this.props.profile.plan !== PLANS.FREE
                                  || index < RESULTS_LIMIT_FREE
                              ) {
                                this.showDataFromUser(record._id);
                              } else {
                                showUpgradeModal({ onOk: () => this.props.history.push('/settings/?upgrade=true') });
                              }
                            },
                            onContextMenu: (e) => {
                              e.preventDefault();
                              if (
                                this.props.profile.plan !== PLANS.FREE
                                  || index < RESULTS_LIMIT_FREE
                              ) {
                                window.open(`${window.location.origin}/#/users/${record._id}`);
                              } else {
                                showUpgradeModal({ onOk: () => this.props.history.push('/settings/?upgrade=true') });
                              }
                            },
                          })}
                        />
                      ) : (
                        <div className="Results_card_wrapper">
                          {(results || [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).map(
                            (account, index) => (
                              <Card
                                className={`Results_card ${
                                  (index >= RESULTS_LIMIT_FREE && !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)) ? 'blur' : ''}`}
                                key={!loading ? account._id : index}
                                onClick={() => {
                                  if ((!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && index >= RESULTS_LIMIT_FREE)) {
                                    showUpgradeModal({ onOk: () => this.props.history.push('/settings/?upgrade=true') });
                                  }
                                }}
                                actions={[
                                  <a
                                    href={`https://www.tiktok.com/@${isValidTikTokUsername(account.username) ? account.username : account.secUid}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    disabled={(index >= RESULTS_LIMIT_FREE
                                    && !isAuthorized(this.props.profile, FEATURES.FULL_DATABASE))}
                                  >
                                    <div className="Tiktok_logo_header_container">
                                      <img className="Tiktok_logo_header_img" src="img/tiktok_logo.png" alt="tiktok" />
                                    </div>
                                  </a>,
                                  this.getNotePopover(account,
                                    false,
                                    !!(!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE) && index >= RESULTS_LIMIT_FREE)),
                                  <Icon
                                    type="user"
                                    onClick={() => {
                                      if (!(!isAuthorized(this.props.profile, FEATURES.FULL_DATABASE)
                                    && index >= RESULTS_LIMIT_FREE)) {
                                        this.showDataFromUser(account._id);
                                      }
                                    }}
                                  />,
                                ]}
                              >
                                <Skeleton loading={loading} avatar active>
                                  <Meta
                                    avatar={(account.profilePicUrl ? (
                                      <Avatar size={50} src={account.profilePicUrl} />
                                    ) : (
                                      <Avatar size={50} icon="user" />
                                    ))}
                                    title={(
                                      <span>
                                        {`@${account.username} `}
                                        {account.verified && <img src="img/verified.png" alt="verified" className="Verified_logo_search" />}
                                      </span>
)}
                                    description={(
                                      <div className="Account_options">
                                        <Tooltip title={
                                        (getCountryNameFromCode(account.region) || {}).name}
                                        >
                                          <Tag color="green">
                                            {flag(account.region)} 
                                            {' '}
                                            {account.region}
                                          </Tag>
                                        </Tooltip>

                                        {account.hasEmail && (
                                        <div>
                                          <Tooltip
                                            trigger={['hover']}
                                            title={(
                                              <>
Click on the
                                                <Icon type="user" />
                                                {' '}
icon to display user's data
                                              </>
)}
                                          >
                                            <Tag color="blue"><Icon type="mail" /></Tag>
                                          </Tooltip>

                                        </div>
                                        )}
                                      </div>
)}
                                  />
                                  <div className="Results_card_data_wrapper">
                                    <div className="Results_card_data">
                                      <Icon type="heart" />
                                      {` ${getCountLabel(account.likesCount)}`}
                                    </div>
                                    <div className="Results_card_data">
                                      <span style={{ lineHeight: 0 }}>
                                        <Icon type="video-camera" />
                                        <b>/w</b>
                                      </span>

                                      {` ${getCountLabel(account.postsPerWeek)}`}
                                    </div>
                                    <div className="Results_card_data">
                                      <Icon type="like" />
                                      {`ER ${getCountLabel(account.globalEngagementRate)}`}
                                    </div>
                                    <div className="Result_vignette_followers_wrapper">
                                      <NumberCountBadge icon={<TKIcon />} color="black" number={getCountLabel(account.followerCount)} />
                                      {/* {account.instagramProfile && account.instagramProfile.followerCount && <NumberCountBadge icon={<Icon type="instagram" />} color="#5ab0ff" number={getCountLabel(account.instagramProfile.followerCount)} />}
                                      {account.youtubeChannel && account.youtubeChannel.subscribers && <NumberCountBadge icon={<Icon type="youtube" />} color="#f4252f" number={getCountLabel(account.youtubeChannel.subscribers)} />} */}
                                    </div>
                                  </div>
                                </Skeleton>
                              </Card>
                            ),
                          )}
                        </div>
                      )}
                      <Pagination
                        className="Pagination_search"
                        size="small"
                        disabled={loading || countLoading}
                        total={Math.min(this.state.count, 10000)}
                        showSizeChanger
                        current={parseInt(page || '1', 10)}
                        onChange={this.onPageChange}
                        onShowSizeChange={this.onPageSizeChange}
                        pageSize={parseInt(limit || '20', 10)}
                        showQuickJumper
                        showTotal={() => (this.state.count ? `${this.state.count > 999999 ? getCountLabel(this.state.count) : this.state.count} results` : '')}
                      />
                    </>
                  )}
              <Modal
                title={accountDetails ? (
                  <a href={`/#/users/${accountDetails}`}>
                    <Icon type="user" />
                    {' '}
Open account page
                  </a>
                ) : ''}
                centered
                visible={!!accountDetails}
                onOk={() => this.closeAccountPreviewModale()}
                onCancel={() => this.closeAccountPreviewModale()}
                footer={null}
                width="760px"
                className="Modale_account_preview_search"
              >
                {accountDetails && (
                <div>
                  <TiktokAccount
                    accountId={accountDetails}
                    showFollowersEvolution={false}
                    profile={profile}
                    getProfile={this.props.getProfile}
                    afterAddInfluencerCallback={this.afterAddInfluencerCallback}
                    afterRemoveInfluencerCallback={this.afterRemoveInfluencerCallback}
                  />
                </div>
                )}
              </Modal>
            </div>
          </div>
          <EditNote
            isVisible={!!this.state.editNoteInfluencer}
            account={this.state.editNoteInfluencer}
            onClose={this.closeEditNoteOnInfluencer}
            callbackSuccess={this.callbackSuccess}
          />
          {isFirstVisit && (profile && !profile.goal) && <FeedbackModal />}
        </div>
      );
    }
}

Search.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
};

Search.defaultProps = {
  location: {},
  history: {},
  profile: {},
  getProfile: () => {},
};

export default withRouter(Search);
