/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import {
  Empty,
  Tag,
  Icon,
  message,
  Modal,
  Menu,
  Dropdown,
  Spin,
  Tabs,
} from 'antd';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuCampaign from '../../components/menuCampaign';
import CreateCampaign from '../../components/createCampaign';
import Header from '../../components/header';
import {
  getCampaignDetails,
  getInfluencersFromCampaign,
  updateCampaign,
  listCampaigns,
} from '../../services/campaigns';
import { STAGES, FEATURES } from '../../services/constants';
import {
  parseSearch,
  stringifySearch,
  isFree,
  isAuthorized,
} from '../../services/utils';
import EditCampaign from '../../components/editCampaign';
import TiktokAccount from '../../components/tiktokAccount';
import {
  isConnected,
} from '../../services/user';
import Creators from './creators';

import '../../styles/global.less';
import '../../styles/campaign.less';

const { TabPane } = Tabs;
class CampaignsView extends Component {
  state={
    influencers: [],
    campaigns: [],
    loading: false,
    campaignDetails: null,
    isEditCampaignOpen: false,
    campaignLoadingError: false,

    getCampaignLoading: false,
    getCampaignListLoading: false,
    getInfluencerListLoading: false,
  }

  constructor(props) {
    super(props);
    if (!isConnected()) {
      this.props.history.push(`/login?next=campaigns/${(props.match.params && props.match.params.id)
        ? props.match.params.id : ''}`);
    }
  }

  componentDidMount() {
    this.updateCampaignPage();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.location.search !== prevProps.location.search)
      || (this.props.location.pathname !== prevProps.location.pathname)
      || (this.props.profile !== prevProps.profile)) {
      const searches = parseSearch(this.props.location.search);
      const prevSearches = parseSearch(prevProps.location.search);
      const isCreateCampaignOpen = searches.create === 'true';
      const wasCreateCampaignOpen = prevSearches.create === 'true';
      if ((isCreateCampaignOpen && !wasCreateCampaignOpen)
      || ((searches.stage !== prevSearches.stage)
      && (this.props.match.params.id === prevProps.match.params.id))) {
        return;
      } if (wasCreateCampaignOpen && !isCreateCampaignOpen) {
        this.getCampaignList(true);
      } else if ((searches.accountDetails === prevSearches.accountDetails)) {
        this.updateCampaignPage();
      }
    }
  }

  updateCampaignPage = (showLoading = true) => {
    window.scrollTo(0, 0);
    Promise.all([
      this.getCampaignList(showLoading),
      this.getCampaign(showLoading),
      this.getInfluencerList(showLoading),
    ]);
  }

  getCampaign = (showLoading = true) => {
    this.setState({ campaignLoadingError: false, getCampaignLoading: !!showLoading });
    if (!this.props.match.params.id) {
      return;
    }
    return getCampaignDetails(this.props.match.params.id)
      .then((res) => {
        if (res.data.archived) {
          this.props.history.push('/campaigns/');
        } else {
          this.setState({ campaignDetails: res.data });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({ campaignLoadingError: true });
      })
      .finally(() => this.setState({ getCampaignLoading: false }));
  }


  getCampaignList = (doLoad = true) => {
    if (doLoad) {
      this.setState({ getCampaignListLoading: true });
    }
    const { archived } = parseSearch(this.props.history.location.search);
    const showArchived = archived === 'true';
    return listCampaigns(showArchived)
      .then(res => this.setState({
        campaigns: isFree(this.props.profile)
          ? res.data.slice(0, 1) : res.data,
      }, () => {
        if ((!this.props.match.params.id || this.props.match.params.id.length === 0)
        && this.state.campaigns.length > 0) {
          this.props.history.push(`/campaigns/${this.state.campaigns[0]._id}`);
        }
      }))
      .catch(error => console.error(error))
      .finally(() => { this.setState({ getCampaignListLoading: false }); });
  }

  getInfluencerList = (showLoading = true) => {
    if (showLoading) {
      this.setState({ getInfluencerListLoading: true });
    }
    if (!this.props.match.params.id) {
      return;
    }
    return getInfluencersFromCampaign(this.props.match.params.id)
      .then(res => this.setState({ influencers: res.data }))
      .catch(e => console.error(e))
      .finally(() => this.setState({ getInfluencerListLoading: false }));
  }

  getTextFromStage = (text) => {
    switch (text) {
      case STAGES.TO_CONTACT:
      default:
        return <Tag color="cyan"><b>TO CONTACT</b></Tag>;
      case STAGES.CONTACTING:
        return <Tag color="blue"><b>CONTACTING</b></Tag>;
      case STAGES.INTERVIEWING:
        return <Tag color="volcano"><b>INTERVIEWING</b></Tag>;
      case STAGES.COLLABORATING:
        return <Tag color="green"><b>COLLABORATING</b></Tag>;
    }
  }


  updateInfluencers = (newInfluencers, callback) => {
    this.setState({ influencers: newInfluencers }, () => {
      this.updateStageNumbers();
      if (callback) {
        callback();
      }
    });
  }

  openEditCampaign = () => this.setState({ isEditCampaignOpen: true })

  closeEditCampaign = () => this.setState({ isEditCampaignOpen: false })

  archiveCampaign=(id) => {
    this.setState({ loading: true });
    updateCampaign({ id, archived: true })
      .then(() => {
        message.success('Campaign archived.');
        this.props.history.push('/campaigns');
      })
      .catch((error) => {
        console.error(error);
        message.error('Error while archiving campaign');
      }).finally(() => { this.setState({ loading: false }); });
  }

  showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this campaign?',
      content: 'You can\'t undo this.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.archiveCampaign(this.state.campaignDetails._id);
      },
    });
  }

  showArchiveConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to archive this campaign?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.archiveCampaign(this.state.campaignDetails._id);
      },
    });
  }

  closeAccountPreviewModale = () => {
    const currentSearch = parseSearch(this.props.location.search);
    delete currentSearch.accountDetails;
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: stringifySearch(currentSearch),
    });
  };

  openAccountPreviewModale = (accountId) => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = { ...currentSearch, accountDetails: accountId };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: stringifySearch(newSearch),
      });
    }
  };

  filterListFromStage = stage => this.state.influencers.filter((i) => {
    if (!stage || stage === 'ALL') {
      return true;
    }
    return i.stage === (stage);
  })

  updateStageNumbers = () => {
    const newstage = Object.keys(STAGES).reduce(
      (acc, st) => ({ ...acc, [st]: this.filterListFromStage(st).length }), {},
    );
    this.setState(prevState => ({
      campaignDetails: {
        ...prevState.campaignDetails,
        stage: newstage,
      },
    }));
  }

  getCurrentCampaign = () => (this.state.campaigns.find(c => c._id === this.props.match.params.id) || {})

  render() {
    const {
      influencers,
      campaignDetails,
      isEditCampaignOpen,
      campaignLoadingError,
      campaigns,
      loading,
      getCampaignLoading,
      getCampaignListLoading,
      getInfluencerListLoading,
    } = this.state;
    const { profile } = this.props;
    const {
      accountDetails, stage, create,
    } = parseSearch(this.props.location.search);
    const isCreateCampaignOpen = create === 'true';

    if (this.props.match.params.id
      && this.props.match.params.id.length > 0
      && campaignLoadingError) {
      return (
        <>
          <Header />
          <div className="div-wrapper" />
        </>
      );
    }
    return (
      <div>
        <Header profile={profile} />
        <div style={{ display: 'flex' }}>
          <MenuCampaign campaigns={campaigns} profile={profile} />
          <div className="Campaign_wrapper">
            <div className="Campaign_inner ">
              <div className="div-wrapper">
                <div className="container">
                  {(campaignLoadingError || !this.props.match.params.id) ? (
                    <div className="div-wrapper">
                      <div className="container">
                        <Empty description={(
                          <p>
                            {this.props.match.params.id
                              ? <b>List not found</b>
                              : <b>No list selected</b>}
                          </p>
)}
                        />
                      </div>
                    </div>
                  ) : (
                    <Spin spinning={loading
                    || getCampaignLoading
                    || getCampaignListLoading || getInfluencerListLoading}
                    >
                      <div className="Campaign_header">
                        <div className="Campaign_element_title_wrapper">
                          <div className="Campaign_element_title ">
                            <h1 className="bold">
                              {campaignDetails ? (
                                <>
                                  {campaignDetails.name}
                                </>
                              ) : 'List'}
                            </h1>
                          </div>
                          <div className="Campaign_element_options">
                            <Dropdown
                              trigger={['click']}
                              overlay={(
                                <Menu>
                                  <Menu.Item>
                                    <a onClick={this.openEditCampaign}>
                                      <Icon type="edit" />
                                      {' '}
Edit name
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <a onClick={this.showDeleteConfirm}>
                                      <Icon type="delete" />
                                      {' '}
Delete
                                    </a>
                                  </Menu.Item>
                                </Menu>
)}
                            >
                              <Icon type="more" className="Icon_options_campaign" />
                            </Dropdown>
                          </div>
                        </div>


                      </div>
                      <Tabs type="card" activeKey="creators">
                        <TabPane
                          tab={(
                            <span>
                              <Icon type="team" />
                              {` Creators (${(this.state.influencers || []).length})`}
                            </span>
)}
                          key="creators"
                        >
                          <Creators
                            influencers={influencers}
                            stage={stage}
                            campaignDetails={campaignDetails}
                            profile={profile}
                            getProfile={this.props.getProfile}
                            openAccountPreviewModale={this.openAccountPreviewModale}
                            updateInfluencers={this.updateInfluencers}
                          />
                        </TabPane>
                        {/* <TabPane
                          tab={(
                            <span>
                              <Icon type="rise" />
                              {` Tracking ${(campaigns && this.getCurrentCampaign().videoCount > 0)
                                ? `(${this.getCurrentCampaign().videoCount || 0})`
                                : ''}`}
                              {(!isAuthorized(profile, FEATURES.TRACKING)) ? (
                                <>
                                  {' '}
                                  <Icon type="lock" />
                                </>
                              ) : <></>}
                            </span>
)}
                          key="tracking"
                        >
                          <Tracking
                            influencers={influencers}
                            campaignDetails={campaignDetails}
                            campaignId={this.props.match.params.id}
                            updateCampaignPage={this.updateCampaignPage}
                            openAccountPreviewModale={this.openAccountPreviewModale}
                            profile={profile}
                          />
                        </TabPane> */}
                      </Tabs>

                    </Spin>
                  )}

                </div>
              </div>
            </div>

          </div>
        </div>
        {campaignDetails && (
        <EditCampaign
          isVisible={isEditCampaignOpen}
          onClose={this.closeEditCampaign}
          refreshCampaigns={() => {
            this.getCampaign();
            this.getCampaignList();
          }}
          campaignToEdit={campaignDetails}
        />
        )}
        {isAuthorized(this.props.profile,
          FEATURES.CREATE_CAMPAIGN,
          { campaigns: this.state.campaigns }) && (
          <CreateCampaign
            isVisible={isCreateCampaignOpen}
            refreshCampaigns={this.getCampaignList}
          />
        )}

        <Modal
          title={accountDetails ? (
            <a href={`/#/users/${accountDetails}`}>
              <Icon type="user" />
              {' '}
Open account page
            </a>
          ) : ''}
          centered
          visible={accountDetails}
          onOk={() => this.closeAccountPreviewModale()}
          onCancel={() => this.closeAccountPreviewModale()}
          footer={null}
          width="760px"
          className="Modale_account_preview_search"
        >
          {accountDetails && (
          <div>
            <TiktokAccount
              accountId={accountDetails}
              showFollowersEvolution={false}
              profile={profile}
              getProfile={this.props.getProfile}
            />
          </div>
          )}
        </Modal>
        {/* {loading && <Loader />} */}

      </div>
    );
  }
}

CampaignsView.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
};

CampaignsView.defaultProps = {
  match: {},
  history: {},
  location: {},
  profile: {},
  getProfile: () => {},
};


export default (withRouter(CampaignsView));
