/* eslint-disable indent */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Empty,
  Icon,
  Menu,
  message,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Modal,
} from 'antd';
import ReactGA from 'react-ga';
import Moment from 'moment';
import { Line } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import flag from 'country-code-emoji';
import { getCountryNameFromCode } from '../services/country';
// import AudienceReportButton from './audienceReportButton';
// import ViewReportButton from './viewReportButton';
import {
  getUserData,
  isConnected,
  requestUserData,
  getFollowedBy,
  getContactInfos,
} from '../services/user';
import NumberCountBadge from './numberCountBadge';
import TKIcon from './tkIcon';
import {
  getCountLabel,
  showUpgradeModal,
  isValidTikTokUsername,
  getCountLabelInteger,
  genetateERdata,
  isAuthorized,
  getTotalCreatorsFromStageObject,
} from '../services/utils';
import {
  addInfluencerToCampaign,
  listCampaigns,
  RemoveInfluencerFromCampaign,
} from '../services/campaigns';
import EditNote from './editNote';
import {
  LINE_DATA,
  FEATURES,
  MAX_CREATORS_PER_CAMPAIGN,
} from '../services/constants';

import '../styles/global.less';
import '../styles/user.less';
import '../styles/tiktokAccount.less';

Moment.locale('en');
const { Text } = Typography;

export const NumberVignette = ({ number, title }) => (
  <div className="NumberVignette_wrapper">
    <div className="NumberVignette_inner">
      <div className="NumberVignette_number popins">
        {getCountLabel(number)}
      </div>
      <div className="NumberVignette_title">{title}</div>
    </div>
  </div>
);

NumberVignette.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
};

NumberVignette.defaultProps = {
  number: 0,
  title: '',
};

class TiktokAccount extends Component {
  SHOW_MORE_LIMIT = 6;

  state = {
    account: null,
    loading: false,
    campaigns: [],
    mentions: null,
    editNoteInfluencerVisible: false,
    showContactInfos: false,
    showPhoneNumber: false,
    showMoreHashtag: false,
    showMoreMention: false,
    // showMoreYoutube: false,
    followedBy: null,
    followedByLoading: false,
    followedByLimit: 22,
  };

  componentDidMount() {
    if (isConnected()) {
      this.getCurrentUserData();
      this.getCampaigns();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountId !== this.props.accountId) {
      this.getCurrentUserData();
    }
  }

  showContactInfos = () => {
    const { accountId } = this.props;
    const { account } = this.state;
    if (accountId && accountId.length > 0) {
      getContactInfos(accountId)
        .then((res) => {
          const newAccount = {
            ...account,
            emails: res.data.emails,
            instagramProfile: {
              ...account.instagramProfile,
              contactPhoneNumber: res.data.instagramProfile.contactPhoneNumber,
              publicEmail: res.data.instagramProfile.publicEmail,
            },
          };
          this.formatUserDataAndSetState(newAccount, () => {
            this.setState({
              showContactInfos: true,
              showPhoneNumber: true,
            });
          });
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 422) {
            message.error(
              <span>
                No more export credits -
                <a href="/#/settings?upgrade=true" style={{ marginLeft: '4px', fontWeight: 'bold' }}>Buy more</a>
              </span>,
            );
          } else {
            message.error('Error while getting the information.');
          }
        });
    }
  };

  showPhoneNumber = () => {
    this.showContactInfos();
  };

  getCurrentUserData = () => {
    this.setState({
      loading: true,
      showContactInfos: false,
      showPhoneNumber: false,
    });
    const { accountId } = this.props;
    if (accountId && accountId.length > 0) {
      getUserData(accountId)
        .then((res) => {
          this.formatUserDataAndSetState(res.data);
          this.setState({ followedByLoading: true });
          getFollowedBy(accountId)
            .then(result => this.setState({ followedBy: result.data }))
            .catch(e => console.error(e))
            .finally(() => this.setState({ followedByLoading: false }));
        })
        .catch(err => console.error('Profile not available', err))
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  formatUserDataAndSetState = (data, callback = () => {}) => {
    const emailsMap = data.emails.reverse().reduce(
      (obj, { value, source }) => ({
        ...obj,
        [value]: obj[value] ? obj[value].concat([source]) : [source],
      }),
      {},
    );
    this.setState(
      {
        account: {
          ...data,
          emails: Object.keys(emailsMap).map(email => ({
            value: email,
            source: emailsMap[email].join(', '),
          })),
        },
        mentions: data.mentions
          ? this.groupBy(
              [
                ...data.mentions,
                ...(data.instagramProfile
                && data.instagramProfile.username
                && data.instagramProfile.mentions
                  ? data.instagramProfile.mentions
                  : []),
              ],
              'username',
            )
          : [],
        tags: data.tags
          ? this.groupBy(
              [
                ...data.tags,
                ...(data.instagramProfile
                && data.instagramProfile.username
                && data.instagramProfile.tags
                  ? data.instagramProfile.tags
                  : []),
              ],
              'tag',
            )
          : [],
        showMoreMention: false,
        showMoreHashtag: false,
        erData: genetateERdata(data),
      },
      () => {
        callback();
      },
    );
  };

  requestUserDataReport = () => {
    this.setState({ loading: true });
    requestUserData(this.props.accountId)
      .then(() => {
        message.success('Request sent.');
      })
      .catch((e) => {
        console.error('Error while requesting data', e);
        if (e.response.status === 404) {
          message.error("Cet utilisateur n'existe pas.");
        } else {
          message.error('There was an error while getting the data');
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  addThisInfluencerToCampaign = (influencer, campaign) => {
    if (
      getTotalCreatorsFromStageObject(campaign.stage)
      >= MAX_CREATORS_PER_CAMPAIGN
    ) {
      message.error('Campaign is full');
    } else {
      this.setState({ loading: true });
      addInfluencerToCampaign(campaign._id, influencer._id)
        .then(() => {
          this.setState({
            account: {
              ...this.state.account,
              campaigns: [...this.state.account.campaigns, campaign],
            },
          });
          message.success(
            <span>
              Influencer added
              {' - '}
              <a href={`/#/campaigns/${campaign._id}`}>View list</a>
            </span>,
            4,
          );
          this.props.afterAddInfluencerCallback(influencer, campaign);
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 409) {
            message.error('Influencer already in this list');
          } else {
            message.error('Error while adding influencer');
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  removeInfluencerFromCampaign = (influencer, campaign) => {
    this.setState({ loading: true });
    RemoveInfluencerFromCampaign(campaign._id, influencer._id)
      .then(() => {
        const campaignsinflu = this.state.account.campaigns.filter(
          c => c._id !== campaign._id,
        );
        this.setState({
          account: {
            ...this.state.account,
            campaigns: campaignsinflu,
          },
        });
        message.success(
          <span>
            Influencer removed
            {' - '}
            <a href={`/#/campaigns/${campaign._id}`}>View list</a>
          </span>,
          4,
        );
        this.props.afterRemoveInfluencerCallback(influencer, campaign);
      })
      .catch((e) => {
        console.error(e);
        if (e.response.status === 409) {
          message.error('Influencer not in the campaign');
        } else {
          message.error('Error while removing influencer');
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getCampaigns = () => listCampaigns()
      .then(res => this.setState({ campaigns: res.data }))
      .catch(error => console.error(error));

  groupBy = (object, param) => object.reduce(
      (acc, current) => ({
        ...acc,
        [current[param]]: {
          [param]: current[param],
          count: acc[current[param]] ? acc[current[param]].count + 1 : 1,
          posts: acc[current[param]]
            ? [
                ...acc[current[param]].posts,
                {
                  postId: current.postId,
                  videoId: current.videoId,
                },
              ]
            : [{ postId: current.postId, videoId: current.videoId }],
        },
      }),
      {},
    );

  openEditNoteOnInfluencer = () => this.setState({ editNoteInfluencerVisible: true });

  closeEditNoteOnInfluencer = () => this.setState({ editNoteInfluencerVisible: false });

  callbackSuccess = note => this.setState(prevState => ({
      account: { ...prevState.account, notes: note },
    }));

  getVerifiedTemplate = () => (
    <div style={{ display: 'flex', marginBottom: '12px' }}>
      <div className="Account_verified_subtitle">
        <img
          src="img/verified.png"
          alt="verified"
          className="Verified_logo_search"
        />
        <div className="Account_verified_text">Verified account</div>
      </div>
    </div>
  );

  showMore = id => this.setState({ [id]: true });

  getShowMore = id => (
    <div className="Vignette_show_more_wrapper">
      <div>
        <Button
          icon="eye"
          type="primary"
          onClick={() => {
            this.showMore(id);
          }}
        >
          Show more
        </Button>
      </div>
    </div>
  );

  shouldBeFlex = (array) => {
    const { showMoreMention, showMoreHashtag } = this.state;
    return (
      (!showMoreMention && !showMoreHashtag)
      || (showMoreMention && showMoreHashtag)
      || Object.values(array).length < this.SHOW_MORE_LIMIT
    );
  };

  openUpgradeModal = () => showUpgradeModal({
      onOk: () => this.props.history.push('/settings/?upgrade=true'),
    });

  openUpgradeBusinessModal = () => showUpgradeModal({
      message:
        'This feature is only available in Startup, Business or Gold plans.',
      onOk: () => this.props.history.push('/settings/?upgrade=true'),
    });

  showMoreFollowedBy = () => this.setState(prevState => ({
      followedByLimit: prevState.followedByLimit + 10,
    }));

  reportCallbackSuccess = id => this.setState(prevState => ({
      account: {
        ...prevState.account,
        report: id,
      },
    }));

  openEmailTemplateModal = () => {
    // log to GA
    ReactGA.event({
      category: 'Email_template',
      action: 'openModal',
    });
    const { profile } = this.props;
    const fn = profile && profile.firstName ? profile.firstName : 'FIRSTNAME';
    const company = profile && profile.companyName ? profile.companyName : 'COMPANY_NAME';
    Modal.success({
      title: 'Email template example',
      okText: 'Close',
      content: (
        <>
          <div>
            Here is an example of a template you can use to reach out to an
            influencer.
            <br />
            Please remember:
            <ol>
              <li>
                Add a personalized touch to the email about the latest content
                or about his/her style.
              </li>
              <li>Be direct and specific about what you're asking.</li>
              <li>Ask about the fee or the process.</li>
            </ol>
            <br />
            <div className="Email_template_code">
              <code>
                Hi!
                <br />
                I'm
                {' '}
                <Text mark>{fn}</Text>
                {' '}
reaching out on behalf of
                {' '}
                <Text mark>{company}</Text>
                . I've been following your account for some time and we are now
                looking for TikTok creators to help engage with our target
                audience. We'd love to work with you to promote our products.
                <br />
                Let us know if you're interested and what your fee would be.
              </code>
            </div>
          </div>
        </>
      ),
    });
  };

  render() {
    const {
      account,
      loading,
      campaigns,
      mentions,
      tags,
      showMoreMention,
      showMoreHashtag,
      //  showMoreYoutube,
      erData,
      followedByLoading,
      followedBy,
      followedByLimit,
    } = this.state;
    const { accountId, showFollowersEvolution, profile } = this.props;
    const followerHistory = account
      ? account.countHistory
          .filter(c => c.type === 'FOLLOWER')
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      : [];

    const dataFollowers = {
      datasets: [
        {
          ...LINE_DATA,
          label: 'Followers',
          legend: {
            display: false,
          },
          data: followerHistory.map(e => ({
            t: new Date(e.createdAt),
            y: e.value,
          })),
        },
      ],
    };
    const dataER = {
      datasets: [
        {
          ...LINE_DATA,
          label: 'Engagement Rate',
          backgroundColor: 'rgb(112, 112, 253, 0.3)',
          borderColor: '#7070fd',
          borderCapStyle: 'butt',
          borderDash: [],
          borderJoinStyle: 'miter',
          pointBorderColor: '#7070fd',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#7070fd',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          legend: {
            display: false,
          },
          data:
            account && erData
              ? erData.map(e => ({ t: new Date(e.createdAt), y: e.er }))
              : null,
        },
      ],
    };

    return (
      <Spin tip="Loading..." spinning={loading}>
        {account && (
          <>
            <div className="User_top_wrapper">
              <div className="User_top_inner">
                <div className="User_top_inner_profile">
                  <div className="User_profile_pic_wrapper">
                    {account.profilePicUrl ? (
                      <Avatar size={80} src={account.profilePicUrl} />
                    ) : (
                      <Avatar size={80} icon="user" />
                    )}
                  </div>
                  <div className="User_name_wrapper">
                    <div className="User_name_fullname popins">
                      {`${account.fullName} `}
                      {account.verification
                        && account.verification.web === true && (
                          <img
                            src="img/verified.png"
                            alt="verified"
                            className="Verified_logo_search"
                          />
                        )}
                    </div>
                    <div className="User_name_username">{`@${account.username}`}</div>
                    {account.region && (
                      <div>
                        <div className="User_name_region">
                          <Icon type="environment" />
                          {' '}
                          {(getCountryNameFromCode(account.region) || {}).name}
                        </div>
                      </div>
                    )}
                    <div className="Signature_wrapper">{account.signature}</div>
                    <div className="Profile_header_nb_count_badge_wrapper">
                      <NumberCountBadge
                        icon={<TKIcon />}
                        color="black"
                        number={getCountLabel(account.followerCount)}
                      />
                      {/* {account.instagramProfile && account.instagramProfile.followerCount && <NumberCountBadge icon={<Icon type="instagram" />} color="#5ab0ff" number={getCountLabel(account.instagramProfile.followerCount)} />}
                     {account.youtubeChannel && account.youtubeChannel.subscribers && <NumberCountBadge icon={<Icon type="youtube" />} color="#f4252f" number={getCountLabel(account.youtubeChannel.subscribers)} />} */}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="User_number_wrapper">
                    <NumberVignette
                      number={account.postsPerWeek}
                      title={`Videos/week (Total: ${account.videosCount})`}
                    />
                    <NumberVignette
                      number={account.averageViewsPerVideo}
                      title="Average Views per Video"
                    />
                    <NumberVignette
                      number={account.globalEngagementRate}
                      title={(
                        <Tooltip title="The engagement rate is computed with the global number of likes, the number of followers and the number of videos.">
                          <span>Engagement</span>
                          {' '}
                          <Icon type="info-circle" />
                        </Tooltip>
)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="User_actions_wrapper">
              <div className="User_actions_inner">
                <div className="User_actions">
                  <a
                    href={`https://www.tiktok.com/@${
                      isValidTikTokUsername(account.username)
                        ? account.username
                        : account.secUid
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size="small" type="primary" icon="link">
                      Open Tiktok profile
                    </Button>
                  </a>
                </div>

                <div className="Flex_right">
                  {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                   &nbsp;
                   &nbsp;
                   {(account.report && account.report.length > 0)
                     ? <ViewReportButton reportId={account.report} />
                     : <AudienceReportButton profile={profile} account={account} getProfile={this.props.getProfile} getReportCallbackSuccess={this.reportCallbackSuccess} />}
                 </div> */}

                  <div className="User_top_select_add_to">
                    <Dropdown
                      trigger={['click']}
                      overlay={(
                        <Menu>
                          {campaigns && campaigns.length > 0 ? (
                            campaigns.map((c) => {
                              const isInfluencerAlreadyInCampaign = account.campaigns.find(
                                  camp => camp._id === c._id,
                                );
                              return (
                                <Menu.Item
                                  key={c._id}
                                  disabled={isInfluencerAlreadyInCampaign}
                                  className={
                                    isInfluencerAlreadyInCampaign
                                      ? 'Search_campaign_select'
                                      : ''
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      if (isInfluencerAlreadyInCampaign) {
                                        this.removeInfluencerFromCampaign(
                                          account,
                                          c,
                                        );
                                      } else {
                                        this.addThisInfluencerToCampaign(
                                          account,
                                          c,
                                        );
                                      }
                                    }}
                                  >
                                    {isInfluencerAlreadyInCampaign && (
                                      <Icon
                                        type="check"
                                        className="Green_check"
                                        style={{ marginRight: '4px' }}
                                      />
                                    )}
                                    {c.name}
                                  </a>
                                </Menu.Item>
                              );
                            })
                          ) : (
                            <span>No campaign found</span>
                          )}
                        </Menu>
)}
                    >
                      <Button
                        size="small"
                        type="primary"
                        className="ant-dropdown-link"
                      >
                        Add to
                        {' '}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="Note_creator_wrapper">
              <div className="Note_creator_inner User_actions_inner">
                {account.notes ? (
                  <div className="flex width-100 Note_creator_inner_wrapper">
                    <div className="Note_creator_icon">
                      <Icon type="message" />
                    </div>
                    <div className="Note_creator_item">{account.notes}</div>
                    <div>
                      <Button
                        icon="edit"
                        type="link"
                        onClick={this.openEditNoteOnInfluencer}
                      >
                        Edit the note
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="center">
                    No private notes for this influencer yet.
                    <span>
                      <Button
                        icon="message"
                        type="link"
                        onClick={this.openEditNoteOnInfluencer}
                      >
                        Add a private note
                      </Button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="User_global_wrapper">
          <div className="max-960 General_wrapper">
            {account && (
              <>
                <div className="Vignette_wrapper_global">
                  <div className="Vignette_grey_wrapper ">
                    <div
                      className="Vignette_email_flex"
                      style={{ maxWidth: '100%' }}
                    >
                      <h2 style={{ marginBottom: '0' }}>
                        <Icon type="mail" theme="twoTone" />
                        &nbsp;&nbsp;
                        {'Contact'}
                      </h2>
                      <br />
                      {(account.emails && account.emails.length > 0)
                      || (account.instagramProfile
                        && account.instagramProfile.contactPhoneNumber) ? (
                          <>
                            <div
                              className={`EmailsWrapper ${
                              this.state.showContactInfos ? '' : 'Blurred'
                            }`}
                            >
                              {account.emails && account.emails.length > 0 ? (
                                <>
                                  <h3>Emails</h3>
                                  {account.emails.map(email => (
                                    <div
                                      key={`${email.value}-${email.source}`}
                                      className="EmailItem"
                                    >
                                      <div>
                                        <Icon type="mail" />
                                        <Typography.Text
                                          code
                                          copyable
                                        >
                                          {`${email.value}`}
                                        </Typography.Text>
                                      </div>
                                      <div className="Email_source">
                                        <i>{`Source: ${email.source}`}</i>
                                      </div>
                                      <Divider />
                                    </div>
                                ))}
                                  {!this.state.showContactInfos && (
                                  <div className="Overlay">
                                    <Button
                                      type="primary"
                                      onClick={
                                        isAuthorized(
                                          profile,
                                          FEATURES.SEE_EMAILS,
                                        )
                                          ? this.showContactInfos
                                          : this.openUpgradeModal
                                      }
                                    >
                                      {`Show ${account.emails.length} email${
                                        account.emails.length > 1 ? 's' : ''
                                      }`}
                                    </Button>
                                  </div>
                                )}
                                </>
                            ) : (
                              <></>
                            )}

                              {account.instagramProfile
                              && account.instagramProfile.contactPhoneNumber && (
                                <div
                                  className={`${
                                    this.state.showPhoneNumber
                                      ? ''
                                      : 'Blurred_phone'
                                  }`}
                                >
                                  <h3>Phone number</h3>
                                  <div className="EmailItemPhone">
                                    <Icon type="phone" />
                                    <Typography.Text code copyable>
                                      {
                                        account.instagramProfile
                                          .contactPhoneNumber
                                      }
                                    </Typography.Text>
                                  </div>
                                  <br />
                                  <div style={{ position: 'relative' }}>
                                    {!this.state.showPhoneNumber && (
                                      <div className="Overlay">
                                        <Button
                                          type="primary"
                                          onClick={
                                            isAuthorized(
                                              profile,
                                              FEATURES.SEE_PHONE_NUMBER,
                                            )
                                              ? this.showPhoneNumber
                                              : this.openUpgradeBusinessModal
                                          }
                                        >
                                          {'Show Phone Number'}
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            {account.emails && account.emails.length > 0 && (
                            <div className="Email_template_link center">
                              <Button
                                type="link"
                                onClick={this.openEmailTemplateModal}
                              >
                                Need an email template ?
                              </Button>
                            </div>
                          )}
                          </>
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No Contact information</span>}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="Vignette_grey_wrapper  flex-3">
                   <div className="Vignette_grey_inner">
                     <div className="flex">
                       <div className="Title_logo_wrapper"><img className="Title_logo" src="img/insta.png" alt="instagram" /></div>
                       <h2 style={{ marginBottom: '0' }}>Instagram</h2>
                     </div>
                     {account.instagramProfile && account.instagramProfile.username ? (
                       <div className="grow1">
                         <div className="User_instagram_details_wrapper grow1">
                           <h4>{account.instagramProfile.fullName}</h4>
                           <div className="flex Count_badge_wrapper_profile">
                             <NumberCountBadge icon={<Icon type="instagram" />} color="#5ab0ff" number={getCountLabel(account.instagramProfile.followerCount)} />
                           </div>

                           {account.instagramProfile.verified && (
                             this.getVerifiedTemplate()
                           )}
                           <p className="Description_social grow1">
                             {account.instagramProfile.biography}
                           </p>
                           <div>
                             <Tag>
                               <b>{account.instagramProfile.mediaCount}</b>
                               {' posts'}
                             </Tag>
                           </div>
                         </div>

                         <Divider />
                         <div className="Instagram_vignette_wrapper">
                           {account.instagramProfile.postsPerWeek
                             ? <NumberVignette number={account.instagramProfile.postsPerWeek} title="Posts per Week" />
                             : <NumberVignette number={account.instagramProfile.mediaCount} title="Posts" />}
                           {account.instagramProfile.engagementRate
                             ? <NumberVignette number={account.instagramProfile.engagementRate} title="Engagement Rate" />
                             : <NumberVignette number={account.instagramProfile.followingCount} title="Followings" />}
                         </div>
                         <Divider />

                         <div style={{ paddingBottom: '4px' }}>
                           <Icon type="instagram" />
                           <a
                             href={`http://instagram.com/${account.instagramProfile.username}`}
                             target="_blank"
                             rel="noopener noreferrer"
                           >
                             {` ${account.instagramProfile.username}`}
                           </a>
                         </div>
                         <br />
                         {account.instagramProfile && (
                         <div className="Last_updated">
                     Last updated:
                           {` ${new Moment(new Date(account.instagramProfile.lastScrapedAt)).format('MM/DD/YYYY - HH:mm')}`}
                         </div>
                         )}
                       </div>
                     ) : (
                       <Empty
                         image={Empty.PRESENTED_IMAGE_SIMPLE}
                         description={<span>No Instagram account</span>}
                       />
                     )
                  }
                   </div>
                 </div>

                 <div className="Vignette_grey_wrapper flex-3">
                   <div className="Vignette_grey_inner">
                     <div className="flex">
                       <div className="Title_logo_wrapper">
                         <img
                           src="img/youtube.png"
                           className="Title_logo"
                           alt="instagram"
                         />
                       </div>
                       <h2 style={{ marginBottom: '0' }}>Youtube</h2>
                     </div>

                     {(account.youtubeChannel
                      && account.youtubeChannel.id
                      && account.youtubeChannel.id.length > 0)
                       ? (
                         <div className="User_instagram_details_wrapper grow1">
                           <h4>{account.youtubeChannel.title}</h4>
                           {account.youtubeChannel.subscribers && (
                           <div className="flex Count_badge_wrapper_profile">
                             <NumberCountBadge
                               icon={<Icon type="youtube" />}
                               color="#f4252f"
                               number={getCountLabel(account.youtubeChannel.subscribers)}
                             />
                           </div>
                           )}
                           {account.youtubeChannel.verified && (
                             this.getVerifiedTemplate()
                           )}
                           <p className="Description_social grow1">
                             {(account.youtubeChannel.description
                             && account.youtubeChannel.description.length > 0)
                               ? (
                                 <div>
                                   {(!showMoreYoutube
                                   && account.youtubeChannel.description.length > 200)
                                     ? (
                                       <>
                                         {account.youtubeChannel.description.substring(0, 200)}
...
                                         <span><a onClick={() => this.showMore('showMoreYoutube')}>Show more</a></span>
                                         {' '}
                                       </>
                                     )
                                     : account.youtubeChannel.description}
                                 </div>
                               ) : (
                                 <p className="italic grey">No Description</p>
                               )}
                           </p>
                           {account.youtubeChannel.videoCount ? (
                             <div>
                               <Tag>
                                 <b>{account.youtubeChannel.videoCount || 0}</b>
                                 {' videos'}
                               </Tag>
                             </div>
                           ) : <></>}
                           <Divider />
                           <div className="Instagram_vignette_wrapper">
                             {account.youtubeChannel.videoCount
                               ? <NumberVignette number={account.youtubeChannel.videoCount} title="Videos" />
                               : <NumberVignette number={account.youtubeChannel.subscribers} title="Subscribers" />}
                             <NumberVignette number={account.youtubeChannel.views} title="Total views" />
                           </div>
                           <Divider />
                           <div>
                             <Icon type="youtube" />
                             {' '}
                             <a
                               href={`https://www.youtube.com/channel/${account.youtubeChannel.id}`}
                               target="_blank"
                               rel="noopener noreferrer"
                             >
                               <span>{account.youtubeChannel.title}</span>
                             </a>
                           </div>

                           <br />
                           <div className="Last_updated">
                     Last updated:
                             {` ${new Moment(new Date(account.youtubeChannel.lastScrapedAt)).format('MM/DD/YYYY - HH:mm')}`}
                           </div>
                         </div>
                       ) : (
                         <Empty
                           image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={<span>No Youtube channel</span>}
                         />
                       )
                      }
                   </div>
                 </div> */}
                </div>

                {account && (
                  <div className="flex width-100">
                    <div
                      className={`flex50 ${
                        this.shouldBeFlex(mentions) ? 'flex' : ''
                      }`}
                    >
                      <div
                        className="Vignette_grey_wrapper"
                        style={{
                          height:
                            showMoreMention
                            || Object.values(mentions).length
                              < this.SHOW_MORE_LIMIT
                              ? 'auto'
                              : '250px',
                        }}
                      >
                        <div style={{ maxWidth: '100%' }}>
                          <div className="flex">
                            <h2 style={{ marginBottom: '0' }}>@ Mentions</h2>
                          </div>
                          <p style={{ color: 'grey' }}>
                            Accounts that were mentionned by @
                            {account.username}
                          </p>
                          {mentions && Object.values(mentions).length > 0 ? (
                            <div className="Mention_list">
                              {Object.values(mentions)
                                .sort((a, b) => b.count - a.count)
                                .slice(
                                  0,
                                  showMoreMention
                                    ? Object.values(mentions).length
                                    : this.SHOW_MORE_LIMIT,
                                )
                                .map(mention => (
                                  <span
                                    className="Mention_vignette"
                                    key={mention.username}
                                  >
                                    <a
                                      href={`https://www.tiktok.com/@${mention.username}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      @
                                      {mention.username}
                                    </a>
                                    {' '}
                                    [
                                    {mention.posts.map((vid, i) => (
                                      <span key={`${vid.videoId}${vid.postId}`}>
                                        <a
                                          href={
                                            vid.videoId
                                              ? `https://www.tiktok.com/@${account.username}/video/${vid.videoId}`
                                              : `https://www.instagram.com/p/${vid.postId}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {vid.videoId ? (
                                            <TKIcon color="blue" />
                                          ) : (
                                            <Icon type="instagram" />
                                          )}
                                          {'('}
                                          {i + 1}
                                          {')'}
                                        </a>
                                        {i < mention.posts.length - 1
                                          ? ', '
                                          : ''}
                                      </span>
                                    ))}
                                    ]
                                  </span>
                                ))}
                              {!showMoreMention
                                && Object.values(mentions).length
                                  > this.SHOW_MORE_LIMIT + 1
                                && this.getShowMore('showMoreMention')}
                            </div>
                          ) : (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No Mentions</span>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex50 ${
                        this.shouldBeFlex(tags) ? 'flex' : ''
                      }`}
                    >
                      <div
                        className="Vignette_grey_wrapper"
                        style={{
                          height:
                            showMoreHashtag
                            || Object.values(tags).length < this.SHOW_MORE_LIMIT
                              ? 'auto'
                              : '250px',
                        }}
                      >
                        <div style={{ maxWidth: '100%' }}>
                          <div className="flex">
                            <h2 style={{ marginBottom: '0' }}># Hashtags</h2>
                          </div>
                          <p style={{ color: 'grey' }}>
                            Hashtags used by @
                            {account.username}
                          </p>
                          {tags && Object.values(tags).length > 0 ? (
                            <div className="Mention_list">
                              {Object.values(tags)
                                .sort((a, b) => b.count - a.count)
                                .slice(
                                  0,
                                  showMoreHashtag
                                    ? Object.values(tags).length
                                    : this.SHOW_MORE_LIMIT,
                                )
                                .map(tag => (
                                  <span
                                    className="Mention_vignette"
                                    key={tag.tag}
                                  >
                                    <a
                                      href={`https://www.tiktok.com/tag/${tag.tag}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      #
                                      {tag.tag}
                                    </a>
                                    {' '}
                                    [
                                    {tag.posts.map((vid, i) => (
                                      <span key={`${vid.videoId}${vid.postId}`}>
                                        <a
                                          href={
                                            vid.videoId
                                              ? `https://www.tiktok.com/@${account.username}/video/${vid.videoId}`
                                              : `https://www.instagram.com/p/${vid.postId}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {vid.videoId ? (
                                            <TKIcon color="blue" />
                                          ) : (
                                            <Icon type="instagram" />
                                          )}
                                          {'('}
                                          {i + 1}
                                          {')'}
                                        </a>
                                        {i < tag.posts.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                    ]
                                  </span>
                                ))}
                              {!showMoreHashtag
                                && Object.values(tags).length
                                  > this.SHOW_MORE_LIMIT + 1
                                && this.getShowMore('showMoreHashtag')}
                            </div>
                          ) : (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No Hashtag used</span>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {profile && isAuthorized(profile, FEATURES.FOLLOWED_BY) && (
                  <div className="Vignette_grey_wrapper width-100">
                    <Spin spinning={followedByLoading}>
                      <div className=" width-100">
                        <h2 style={{ marginBottom: '0' }}>
                          <Icon type="user" style={{ fontWeight: 'bold' }} />
                          {' '}
                          Famous followers
                        </h2>
                        <p style={{ color: 'grey' }}>
                          Other influencers who follow @
                          {account.username}
                        </p>
                        {followedBy && followedBy.length > 0 ? (
                          <div>
                            <div className="Followedby_list">
                              {followedBy
                                .slice(0, followedByLimit)
                                .map(fb => (
                                  <a
                                    href={`/#/users/${fb._id}`}
                                    key={fb._id}
                                    className="Followedby_element"
                                  >
                                    <div className="Followedby_followersCount">
                                      <Tag>
                                        {flag(fb.region)}
                                        {' '}
                                        {getCountLabel(fb.followerCount)}
                                      </Tag>
                                    </div>
                                    <div>
                                      {fb.profilePicUrl ? (
                                        <Avatar
                                          size={50}
                                          src={fb.profilePicUrl}
                                        />
                                      ) : (
                                        <Avatar size={50} icon="user" />
                                      )}
                                      {fb.verified && (
                                        <img
                                          src="img/verified.png"
                                          alt="verified"
                                          className="Verified_logo_search"
                                        />
                                      )}
                                      <div className="FollowedBy_username">
                                        @
                                        {fb.username}
                                      </div>
                                    </div>
                                  </a>
                                ))}
                            </div>
                            <div className="center">
                              <br />
                              {followedBy.length > followedByLimit ? (
                                <Button
                                  type="link"
                                  onClick={this.showMoreFollowedBy}
                                >
                                  Show more (
                                  {followedBy.length - followedByLimit}
)
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={(
                              <span>
                                This account is not followed by any other
                                influencer
                              </span>
)}
                          />
                        )}
                      </div>
                    </Spin>
                  </div>
                )}

                {showFollowersEvolution ? (
                  <div className="flex width-100">
                    <div className="flex-50">
                      <div className="Vignette_grey_wrapper Vignette_graph_wrapper">
                        <h2>Followers evolution</h2>
                        <div>
                          {dataFollowers
                          && dataFollowers.datasets
                          && dataFollowers.datasets[0].data.length > 0 ? (
                            <Line
                              data={dataFollowers}
                              options={{
                                plugins: {
                                  datalabels: {
                                    display: false,
                                  },
                                },
                                legend: {
                                  display: false,
                                },
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: false,
                                        callback(value) {
                                          return getCountLabelInteger(value);
                                        },
                                      },
                                    },
                                  ],
                                  xAxes: [
                                    {
                                      type: 'time',
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No evolution to show</span>}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex-50">
                      <div className="Vignette_grey_wrapper Vignette_graph_wrapper">
                        <h2>Engagement Rate evolution</h2>
                        <div>
                          {dataER
                          && dataER.datasets
                          && dataER.datasets[0].data.length > 0 ? (
                            <Line
                              data={dataER}
                              options={{
                                plugins: {
                                  datalabels: {
                                    display: false,
                                  },
                                },
                                legend: {
                                  display: false,
                                },
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: false,
                                      },
                                    },
                                  ],
                                  xAxes: [
                                    {
                                      type: 'time',
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<span>No evolution to show</span>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="center Text_overflow"
                    style={{ margin: 'auto', paddingBottom: '30px' }}
                  >
                    <br />
                    <a href={`/#/users/${account._id}`}>
                      <Button type="link" icon="user">
                        Open the profile page to see the follower evolution
                        overtime.
                      </Button>
                    </a>
                    <br />
                  </div>
                )}
                {accountId && !account && (
                  <Empty
                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No account found.</span>}
                  >
                    <Button type="primary" onClick={this.requestUserDataReport}>
                      Request the report
                    </Button>
                  </Empty>
                )}
              </>
            )}
          </div>
        </div>
        <EditNote
          isVisible={this.state.editNoteInfluencerVisible}
          account={account}
          onClose={this.closeEditNoteOnInfluencer}
          callbackSuccess={this.callbackSuccess}
        />
      </Spin>
    );
  }
}

TiktokAccount.propTypes = {
  accountId: PropTypes.string,
  showFollowersEvolution: PropTypes.bool,
  profile: PropTypes.object,
  history: PropTypes.object,
  // getProfile: PropTypes.func,
  afterAddInfluencerCallback: PropTypes.func,
  afterRemoveInfluencerCallback: PropTypes.func,
};

TiktokAccount.defaultProps = {
  accountId: null,
  showFollowersEvolution: true,
  profile: {},
  history: {},
  // getProfile: () => {},
  afterAddInfluencerCallback: () => {},
  afterRemoveInfluencerCallback: () => {},
};

export default withRouter(TiktokAccount);
