/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import {
  Spin,
  Menu,
  Divider,
  Icon,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FEATURES } from '../services/constants';
import {
  parseSearch,
  stringifySearch,
  showUpgradeModal,
  isAuthorized,
  isBannerDisplayed,
} from '../services/utils';
import '../styles/global.less';
import '../styles/menuCampaign.less';


class MenuCampaign extends Component {
  state={
    menuCollapsed: false,
    width: null,
    openKeys: [],
    selected: [],
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.initOpenKeys();
    this.initSelected();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.location.search !== prevProps.location.search)
      || (this.props.location.pathname !== prevProps.location.pathname)) {
      this.collapseMenuIfScreenisSmall();
      this.initOpenKeys();
      this.initSelected();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions=() => {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth }, () => this.collapseMenuIfScreenisSmall());
    }
  }

  collapseMenuIfScreenisSmall = () => this.setState(prevState => ({
    menuCollapsed: prevState.width < 800,
  }));

  initOpenKeys = () => this.setState({ openKeys: [this.props.match.params.id] })

  initSelected = () => {
    const path = this.props.location.pathname.split('/');
    const isTracking = path[path.length - 1] === 'tracking';
    this.setState({ selected: `${this.props.match.params.id}.${isTracking ? 'TRACKING' : 'CREATORS'}` });
  }

  toggleCollapsedMenu = () => this.setState(prevState => ({
    menuCollapsed: !prevState.menuCollapsed,
  }))

  handleClickMenu = (e) => {
    if (e.key === 'title') {
      this.toggleCollapsedMenu();
      return;
    } if (e.key === 'create') {
      if (!isAuthorized(this.props.profile,
        FEATURES.CREATE_CAMPAIGN,
        { campaigns: this.props.campaigns })) {
        showUpgradeModal({
          message: 'You can\'t create more campaigns with your current plan.',
          onOk: () => this.props.history.push('/settings/?upgrade=true'),
        });
      } else {
        const currentSearch = parseSearch(this.props.location.search);
        const newSearch = { ...currentSearch, create: true };
        this.props.history.push({
          pathname: `/campaigns/${this.props.match.params.id ? this.props.match.params.id : ''}`,
          search: stringifySearch(newSearch),
        });
      }

      return;
    } if (e.key === 'archived') {
      this.props.history.push('/campaigns/archived');
      return;
    }
    this.props.history.push({
      pathname: `/campaigns/${e.key}/`,
    });
    window.scrollTo(0, 0);
  };

  toggleOpenKeys = (id) => {
    this.setState(prevState => ({
      openKeys: prevState.openKeys.includes(id)
        ? prevState.openKeys.filter(c => c !== id)
        : [...prevState.openKeys, id],
    }));
  }

  oncChangeSelectedKey = id => this.setState({ selected: [id] })

  render() {
    const {
      menuCollapsed, openKeys, selected,
      width,
    } = this.state;
    const { campaigns, loading, profile } = this.props;
    return (
      <>
        <div className={`${menuCollapsed ? 'Menu_pad_left_closed' : ''} Menu_pad_left`} />
        <Spin spinning={loading}>
          <div className={`Menu_wrapper ${isBannerDisplayed(width, profile) ? 'Menu_pad_banner' : ''}`}>
            <Menu
              inlineCollapsed={menuCollapsed}
              onClick={this.handleClickMenu}
              style={{ maxWidth: 256 }}
              mode="inline"
              selectedKeys={selected}
              openKeys={menuCollapsed ? [] : openKeys}
              className="Menu_campaign_wrapper"
            >
              <Menu.Item key="title">
                <Icon type="menu" />
                <span>
Lists
                  {campaigns ? ` (${campaigns.length})` : ''}
                </span>
              </Menu.Item>
              {(campaigns || []).map(c => (
                <Menu.Item
                  key={c._id}
                >
                  <span>
                    <Icon type="project" />
                    <span>{c.name}</span>
                  </span>
                </Menu.Item>
              ))
        }
              <Divider />
              <Menu.Item key="create">
                <Icon type="plus" />
                <span>
Create list
                </span>
              </Menu.Item>
            </Menu>
          </div>
        </Spin>
      </>
    );
  }
}

MenuCampaign.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  campaigns: PropTypes.array,
  loading: PropTypes.bool,
  profile: PropTypes.object,
};

MenuCampaign.defaultProps = {
  match: {},
  history: {},
  profile: {},
  location: {},
  campaigns: [],
  loading: false,
};


export default withRouter(MenuCampaign);
